import { Directive, HostBinding, ViewContainerRef } from "@angular/core"

@Directive({
  selector: '[inscErrorViewHost]'
})
export class ErrorViewHostDirective {

  @HostBinding("class") class = "xyz";

  constructor(public viewContainerRef: ViewContainerRef) { }

}
