import { HttpErrorResponse } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { Inscription } from "../shared/models/inscription.model"
import { InscObject } from "../shared/models/object.model"
import { EntityType } from "./data.service"

export class APIError {
  public error: string
  constructor(public errorResponse: any) {
    this.error = errorResponse["error"]
  }

  toString() { return this.error }
}

export class DeletePreventedByAssociationsError extends APIError {

  public entities: {
    id: string;
    name: string;
    inscriptions?: Partial<Inscription>[];
    insc_objects?: Partial<InscObject>[]
  }[]

  public blockingEntityType: EntityType

  constructor(public errorResponse: any) {
    super(errorResponse)

    this.entities = errorResponse["entities"]
    this.blockingEntityType = errorResponse["blockingEntityType"]
  }
}

export class DatabaseError extends APIError {
  public message: string
  public type: string

  constructor(public errorResponse: any) {
    super(errorResponse)

    this.message = errorResponse["message"]
    this.type = errorResponse["type"]
  }
}

export class UploadError extends APIError {
  public message: string

  constructor(public errorResponse: any) {
    super(errorResponse)

    this.message = errorResponse["message"]
  }
}


export interface IValidationErrorDict  {
  [fieldName: string]: [
    { error: string }[]
  ]
}

export class ValidationError extends APIError {
  public field_errors: IValidationErrorDict

  constructor(public errorResponse: any) {
    super(errorResponse)

    this.field_errors = errorResponse["field_errors"]
  }
}

export class LinkingError extends APIError {
  readonly message: string

  constructor(public errorResponse: any) {
    super(errorResponse)
    this.message = errorResponse["message"]
  }
}

export class NotAuthorizedError extends APIError { }
export class NoPermissionError extends APIError { }

@Injectable({
  providedIn: "root"
})
export class HTTPErrorService {
  getError(errorResponse: HttpErrorResponse): APIError {
    const error = errorResponse.error?.["error"]
    if (!error) {
      return new APIError(errorResponse)
    }

    switch (error["error"]) {
      case "DeletionBlockedByAssociationsError":
        return new DeletePreventedByAssociationsError(error)
      case "DatabaseError":
        return new DatabaseError(error)
      case "ValidationError":
        return new ValidationError(error)
      case "NotAuthorizedError":
        return new NotAuthorizedError(error)
      case "NoPermissionError":
        return new NoPermissionError(error)
      case "LinkingError":
        return new LinkingError(error)
      case "UploadError":
        return new UploadError(error)
      default:
        return new APIError(error)
    }
  }
}
