import { Component, Input } from "@angular/core"

@Component({
  selector: 'insc-database-error-view',
  template: `
    <h3>{{ type }}</h3>
    <div class="message">
       {{ message }}
    </div>
  `,
  styles: []
})
export class DatabaseErrorViewComponent {

  @Input() type: string
  @Input() message: string

  constructor() { }


}
