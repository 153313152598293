import { Injectable } from "@angular/core"
import { MatSnackBar } from "@angular/material/snack-bar"

import { APIError } from "../../services/errors"
import { ErrorSnackbarComponent } from "./error-snackbar/error-snackbar.component"

import { SuccessSnackbarComponent } from "./success-snackbar/success-snackbar.component"


@Injectable()
export class SnackbarService {

  constructor(private matSnackbarService: MatSnackBar) { }

  showSuccessWithMessage(message: string) {
    this.matSnackbarService.openFromComponent(SuccessSnackbarComponent, {
      data: {
        message: message
      },
      duration: 3000
    })
  }

  showApiError(error: APIError) {
    this.matSnackbarService.openFromComponent(ErrorSnackbarComponent, {
      data: {
        error: error
      }
    })
  }
}
