import { HttpBackend, HttpClient } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { BehaviorSubject, Observable, of } from "rxjs"
import { map, shareReplay, take } from "rxjs/operators"

import { environment } from "../../environments/environment"
import { User } from "../shared/models/user.model"

const AUTH_TOKEN_ITEM_KEY = "authToken"
const CURRENT_USER_ITEM_KEY = "currentUser"

export interface AuthResponse {
  success: "true"
  token: string
  user: User
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  readonly baseUrl = environment.apiUrl

  private _currentUser = new BehaviorSubject<User>(this.getCurrrentUser())
  currentUser = this._currentUser.asObservable()

  constructor(private http: HttpClient, private httpBackend: HttpBackend) { }

  login(username: string, password: string) {
    const loginRequest = this.http.post<AuthResponse>(`${this.baseUrl}/login`, {username, password}).pipe(
      shareReplay())

    loginRequest.pipe(take(1)).subscribe(
      response => {
        localStorage.setItem(AUTH_TOKEN_ITEM_KEY, response.token)
        this.setCurrentUser(response.user)
      },
      () => this.logout())

    return loginRequest
  }

  logout() {
    localStorage.removeItem(AUTH_TOKEN_ITEM_KEY)
    localStorage.removeItem(CURRENT_USER_ITEM_KEY)
    this._currentUser.next(null)
  }

  getCurrrentUser() {
    return <User>JSON.parse(localStorage.getItem(CURRENT_USER_ITEM_KEY))
  }

  getAuthToken() {
    return localStorage.getItem(AUTH_TOKEN_ITEM_KEY)
  }

  private setCurrentUser(user: User) {
    localStorage.setItem(CURRENT_USER_ITEM_KEY, JSON.stringify(user))
    this._currentUser.next(user)
  }

  // https://stackoverflow.com/questions/46469349/how-to-make-an-angular-module-to-ignore-http-interceptor-added-in-a-core-module
  reloadUserData() {

    const http = new HttpClient(this.httpBackend)

    const currentUser = this.getCurrrentUser()
    if (currentUser) {
      return new Observable<User>(subscriber => {
        return http.get<{user: User}>(`${this.baseUrl}/users/${currentUser.id}`).pipe(take(1)).subscribe(response => {
          this.setCurrentUser(response.user)
          subscriber.next(response.user)
          subscriber.complete()
        })
      })
    } else {
      return of(null)
    }
  }

  hasPermission(permission: string): boolean {
    const currentUser = this.getCurrrentUser()
    return currentUser && currentUser.permissions[permission] === true

    
  }

  permission(permission: string): Observable<boolean> {
    return this.currentUser.pipe(map(() => this.hasPermission(permission)))
  }

}
