import { Component, Input } from "@angular/core"

@Component({
  selector: 'insc-error-view-basic',
  template: `
    {{ message }}
  `,
  styles: []
})
export class ErrorViewBasicComponent {

  @Input() message: string

  constructor() { }


}
