<form [formGroup]="loginForm" (ngSubmit)="tryLogin()" class="mat-typography">
  <h2 mat-dialog-title>Datenbank-Login</h2>
  <mat-dialog-content class="content">
    @if (data && data.message) {
      <div class="error">{{data.message}}</div>
    }

    <mat-form-field>
      <input matInput formControlName="username"/>
      <mat-label>Benutzername</mat-label>
    </mat-form-field>
    <mat-form-field>
      <input matInput type="password" formControlName="password" #passwordInput="matInput" />
      <mat-label>Passwort</mat-label>
    </mat-form-field>

    <div class="error" [hidden]="!fail">{{fail}}</div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button type="submit" color="primary">Anmelden</button>
  </mat-dialog-actions>
</form>
