export const environment = {
  production: true,
  playground: false,
  apiUrl: "https://inschriften.cceh.uni-koeln.de/api/",
  sourcemap: "true",
  hmr: false,
  titlePrefix: "",

  // TODO: put in injectable
  urnResolver: "http://nbn-resolving.de/"
}
