import { Component, Input } from "@angular/core"

import { EntityType } from "../../../../services/data.service"
import { DeletePreventedByAssociationsError } from "../../../../services/errors"

@Component({
  selector: 'insc-delete-prevented-by-association-error-view',
  template: `
    <div>
      Der gewählte Eintrag kann nicht gelöscht werden, da folgende
      <span>
        @switch (error.blockingEntityType) {
          @case ('insc_object') {
            <span>Inschriftenträger</span>
          }
          @case ('inscription') {
            <span>Inschrift(en)</span>
          }
          @case ('image') {
            <span>Aufnahmen</span>
          }
          @case ('location') {
            <span>Orte</span>
          }
        }
      </span>
      mit ihm verknüpft sind:
      <ul>
        @for (entity of error.entities; track entity) {
          <li>
            @switch (error.blockingEntityType) {
              @case ('location') {
                <span>{{entity.name}}</span>
              }
              @case ('inscription') {
                <a [routerLink]="['objects', entity.insc_objects?.[0]?.id, 'inscriptions', entity.id]" target="_blank">{{entity.name}}</a>
              }
              @default {
                <a [routerLink]="[getRouteForEntityType(error.blockingEntityType), entity.id]" target="_blank">{{entity.name}}</a>
              }
            }
          </li>
        }
      </ul>
    </div>
    `,
  styles: [],
})
export class DeletePreventedByAssociationErrorViewComponent {
  @Input() error: DeletePreventedByAssociationsError

  getRouteForEntityType(entityType: EntityType): string {
    switch (entityType) {
      case "insc_object": return "objects"
    }
  }

}
