import { AfterViewInit, Component, Inject, ViewChild } from "@angular/core"
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms"
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog"
import { MatInput } from "@angular/material/input"
import { AuthService } from "../../../services/auth.service"
import { SnackbarService } from "../../../shared/snackbars/snackbar.service"

export interface LoginDialogData {
  message: string
}

@Component({
  selector: 'insc-login-dialog',
  templateUrl: './login-dialog.component.html',
  styleUrls: ['./login-dialog.component.scss']
})
export class LoginDialogComponent implements AfterViewInit {

  loginForm = new UntypedFormGroup({
    // eslint-disable-next-line @typescript-eslint/unbound-method
    username: new UntypedFormControl(null, Validators.required),
    // eslint-disable-next-line @typescript-eslint/unbound-method
    password: new UntypedFormControl(null, Validators.required)
  })

  fail: string | null = null

  get username() { return this.auth.getCurrrentUser() && this.auth.getCurrrentUser().name }

  @ViewChild('passwordInput', {static: true}) passwordInput: MatInput

  constructor(
    private dialogRef: MatDialogRef<LoginDialogComponent>,
    private auth: AuthService,
    private snackbarService: SnackbarService,
    @Inject(MAT_DIALOG_DATA) public data: LoginDialogData
  ) { }

  ngAfterViewInit(): void {
    if (this.username) {

      // prefill last username if available
      this.loginForm.patchValue({username: this.username})

      // without the timeout, focus will always jump back to the username field
      // for some unfathomable reason
      setTimeout(() => this.passwordInput.focus(), 500)
    }
  }

  tryLogin() {
    this.auth.login(this.loginForm.value["username"], this.loginForm.value["password"])
      .subscribe(
        () => {
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          this.snackbarService.showSuccessWithMessage(`Eingeloggt als Benutzer ${this.loginForm.get("username").value}.`)
          this.dialogRef.close(true)
        },
        (error) => {
          this.fail = error["errorResponse"]
        }
      )
  }

}
